@import "~bootstrap/scss/bootstrap";
@import "./vars.scss";

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  background-color: #16191D;
}

h1 {
  color: $primaryColor;
}

p {
  color: #FFFFFF;
  margin-bottom: 0;
}

html, body, #root, .App {
  height: 100%;
}
