.heading {
  margin-bottom: 20px;

  .staking {
    color: #ffffff;
    background-color: #16191d;
  }

  .frag {
    font-weight: 700;
    color: #00e6ac;
  }
}

.heading h1 {
  display: inline-block;
  margin: 0px 12px;
}

.staking {
  .staking-wrapper {
    background-color: #1c1e21;
    max-width: 428px;

    .staking-form-wrapper {
      margin-top: 0px !important;
    }

    .input {
      padding-bottom: 10px;
      border-bottom: 1px solid #545658;

      .data {
        position: relative;

        input {
          width: 100%;
        }

        p {
          font-weight: 800;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.input {
  width: 100%;
}

#input {
  width: 100% !important;
  height: 32px !important;
  all: unset;
  padding: 5px;
  background-color: black;
  color: white;
  border-radius: 12px;
}

/* Chrome, Safari, Edge, Opera */
#input::-webkit-outer-spin-button,
#input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#input[type='number'] {
  -moz-appearance: textfield;
}

.mint-btn {
  background-color: #00e6ac !important;
  color: #1c1e21 !important;
  font-weight: 600 !important;
  border: none !important;
}

.mint-btn:disabled {
  background-color: #292b2e !important;
  color: white !important;
}

.stake-btn {
  gap: 1rem;
  border-bottom: 1px solid #545658;
  button {
    flex-grow: 1;
    height: 46px !important;
  }
}

.restake-btn {
  gap: 12px;
  margin-top: 12px;
  button {
    height: 26px;
    padding: 0px 12px;
  }
}

.line {
  border-bottom: 1px solid #545658;
  width: 80% !important;
  margin: auto !important;
  margin-top: 25px !important;
}

.modal-content {
  background-color: #2a2c2f !important;

  .modal-title {
    color: white;
  }
}
.max-button {
  background-color: #00e6ac !important;
  color: #1c1e21 !important;
  font-weight: 600 !important;
  border-radius: 5px;
  border: 1px solid transparent;
  transition: all 0.3s;
  &.disable {
    background-color: #292b2e !important;
    color: #a0a1a2 !important;
    cursor: default;
    &:hover {
      border: 1px solid transparent;
    }
  }
}

.text-notification {
  color: #a0a1a2;
  font-size: 14px;
}
